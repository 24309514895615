import React, { Children } from "react";
import Grid, { GridSize } from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import _ from "lodash";

import { useLocation } from "react-router";

import BookingFormField from "./BookingFormField";
import { Loader } from "../Loader";

import { useFieldsSettings } from "context/fieldsSettings";

import { useStore } from "../../context/store";
import { FormErrors } from "hooks/bookings/bookingForm";

import { useLang } from "../../context/lang";

import KeyListener from "components/KeyListener";
import CreateAddress from "containers/CreateAddress";
import PackagesEditContainer from "../PackagePicker/PackagesEditContainer";
import {
  BookingFieldId,
  IBooking,
  FieldParameters,
  ICustomersCustomer,
} from "navision-proxy-api/@types";

//TODO: forward props from bookingForm hook as a pack, not separate props (props hell)
interface IProps {
  booking: Partial<IBooking>;
  handleChangeField: (key: BookingFieldId, value: any) => void;
  setBooking: (booking: Partial<IBooking>) => void;
  pushCustomersCustomer: (cc: ICustomersCustomer) => void;
  setEnableCCLookup: (v: boolean) => void;
  customersCustomerLookup: ICustomersCustomer[];
  errors: FormErrors;
  handleClearAddresses: (field: keyof IBooking) => void;
  handleSubmit?: () => void;
  loading: boolean;
  ignoredFields: BookingFieldId[];
  /** Show input fields as full width */
  fullWidth?: boolean;
  hideDateTimeFields?: boolean;
  hideRegularFields?: boolean;
  hidePackagePickers?: boolean;
  hideSaveButton?: boolean;
  disableHiddenFields?: boolean;
}

export const BookingForm = ({
  booking,
  handleChangeField,
  setBooking,
  handleSubmit,
  customersCustomerLookup,
  pushCustomersCustomer,
  setEnableCCLookup,
  errors,
  handleClearAddresses,
  loading,
  ignoredFields = [],
  fullWidth = false,
  hideDateTimeFields = false,
  hideRegularFields = false,
  hidePackagePickers = false,
  hideSaveButton = false,
  disableHiddenFields = false,
}: IProps) => {
  const { bookingFormData } = useStore();
  const { pathname } = useLocation();

  const submitButtonRef = React.useRef<HTMLButtonElement>(null);

  const fields = bookingFormData.fields.filter(
    (f) => !ignoredFields.includes(f.id) && !f.isPackage
  );

  const fieldSettings = useFieldsSettings();

  const hiddenFields = disableHiddenFields ? [] : fieldSettings.hiddenFields;

  const { t } = useLang();

  const renderField = (
    field: FieldParameters,
    {
      gridSize = { xs: 12 as GridSize, sm: 6 as GridSize, md: 6 as GridSize },
      index = 0,
    }
  ) => {
    return (
      <Box
        key={field.id}
        order={index}
        display="flex"
        flexDirection="column"
        clone
        pr={3}
        pt={1}
        mx={1}
      >
        <Grid
          key={field.id}
          style={field.readOnly ? { display: "none" } : {}}
          item
          {...gridSize}
        >
          <BookingFormField
            field={field}
            booking={booking}
            customersCustomerLookup={customersCustomerLookup}
            value={booking[field.id as keyof IBooking] || ""}
            //defaultValue={!defaultBookingParams.isLocal && field.defaultValue}
            defaultValue={field.default}
            onChange={handleChangeField}
            // required={validators[field.id]?.find(
            //   ({ name }) => name === "required"
            // )}
            onClear={
              field.isAddress
                ? () => handleClearAddresses(field.id as keyof IBooking)
                : undefined
            }
            fullWidth={fullWidth}
            error={errors[field.id] as string}
          />
          {field.isAddress && (
            <CreateAddress
              booking={booking}
              addressField={field.id}
              setBooking={setBooking}
              pushCustomersCustomer={pushCustomersCustomer}
              setEnableCCLookup={setEnableCCLookup}
            />
          )}
        </Grid>
      </Box>
    );
  };

  const dateTimeFields = !hideDateTimeFields
    ? fields
        .filter(
          ({ id, isDateTime }) => !hiddenFields.includes(id) && isDateTime
        )
        .map((field) =>
          renderField(field, { gridSize: { xs: 12, sm: 6, md: 4 } })
        )
    : null;

  const addressFields = !hideRegularFields
    ? fields
        .filter(({ id, isAddress }) => isAddress && !hiddenFields.includes(id))
        .map((field, index) => {
          return renderField(field, {
            index:
              index +
              Children.count(dateTimeFields) +
              (field.flexOrderOffset ? field.flexOrderOffset - 1 : 0),
          });
        })
    : null;

  const regularFields = !hideRegularFields
    ? fields
        .filter(
          ({ id, isDateTime, isAddress }) =>
            ![
              "goods",
              "goods.$*",
              "packages",
              "packages.$*",
              "goodsDescription",
              "packagesDescription", //TODO change this to prop
            ].includes(id) &&
            !hiddenFields.includes(id) &&
            !isDateTime &&
            !isAddress
        )
        .map((field, index) => {
          return renderField(field, {
            index:
              index +
              Children.count(dateTimeFields) +
              Children.count(addressFields) +
              (field.flexOrderOffset ? field.flexOrderOffset - 1 : 0),
          });
        })
    : null;

  return (
    <Grid container>
      {" "}
      <>
        {!hideDateTimeFields && (
          <Box clone>
            <Grid item xs={12} container justify="flex-start">
              {dateTimeFields}
            </Grid>
          </Box>
        )}
      </>
      <>
        {Children.count(addressFields) > 0 && (
          <Box
            display="flex"
            flexWrap="wrap"
            flexDirection={{ xs: "row", sm: "column" }}
            maxHeight={{
              xs: Math.ceil(Children.count(addressFields)) * 120,
              sm: Math.ceil(Children.count(addressFields) / 2) * 120,
            }}
            width={1}
            mt={2}
          >
            {addressFields}
          </Box>
        )}
      </>
      <>{regularFields}</>
      <>
        {!hidePackagePickers ? (
          <Box
            order={
              Children.count(dateTimeFields) + Children.count(regularFields) + 3
            }
            clone
            mt={2}
          >
            <Grid item xs={12} container>
              <PackagesEditContainer
                id="goods"
                label={t("goods")}
                onChange={(goods) => handleChangeField("goods", goods)}
                value={booking.goods}
                packagesLookup={bookingFormData.goodNames}
                descriptionLabel={t("goodsDescription")}
                packagesDescriptions={booking.goodsDescription} //array
                onChangePackageDescriptions={
                  !hiddenFields.includes("goodsDescription")
                    ? (index: number, value: string) => {
                        const newGoodsDescription = booking.goodsDescription
                          ? [...booking.goodsDescription]
                          : [];
                        newGoodsDescription[index] = value;
                        handleChangeField(
                          "goodsDescription",
                          newGoodsDescription
                        );
                      }
                    : undefined
                }
                errorText={errors["goods"]}
                valueTranslatePrefix="goods:"
                enableDuplicateKeys
              />
              {!ignoredFields.includes("packages") && (
                <PackagesEditContainer
                  id="packages"
                  label={t("packages")}
                  onChange={(packages) =>
                    handleChangeField("packages", packages)
                  }
                  packagesLookup={bookingFormData.packageNames}
                  value={booking.packages}
                  descriptionLabel={t("packagesDescription")}
                  description={booking.packagesDescription?.join("") || ""}
                  onChangeDescription={
                    !hiddenFields.includes("packagesDescription")
                      ? (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeField("packagesDescription", [
                            e.target.value,
                          ])
                      : undefined
                  }
                  valueTranslatePrefix="packages:"
                  row
                  errorText={errors["packages"]}
                />
              )}
            </Grid>
          </Box>
        ) : null}
      </>
      <>
        {!hideSaveButton && (
          <Box
            order={
              Children.count(dateTimeFields) + Children.count(regularFields) + 4
            }
            mt={2}
            clone
          >
            <Grid item xs={12} container justify="flex-start">
              <KeyListener
                keyName="Enter"
                ctrl
                action={(e) => {
                  //couldn't call handleSubmit directly because than it dont see actual version of booking
                  if (submitButtonRef.current) {
                    const activeElement = document.activeElement;
                    if (activeElement instanceof HTMLElement) {
                      activeElement?.blur();
                    }
                    submitButtonRef.current["click"]();
                  }
                }}
              >
                <Box mt={2} clone>
                  <Button
                    id="save"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmit}
                    ref={submitButtonRef}
                    startIcon={
                      <Loader size={20} loading={loading}>
                        <Icon>save</Icon>
                      </Loader>
                    }
                  >
                    {t("save")}
                  </Button>
                </Box>
              </KeyListener>
            </Grid>
          </Box>
        )}
      </>
    </Grid>
  );
};
