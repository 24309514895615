import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import Box from "@material-ui/core/Box";
import { useLang } from "../context/lang";
import { useDraggable } from "hooks/draggable";
import { useAuth } from "context/auth";
import { COLUMN_FREEZE_INDEX } from "config/index";

export interface IFieldsMenuOption {
  title: string;
  id: string;
  value: boolean;
  /** option can be hidden in the menu
   * but still counted in the drag & drop */
  hidden?: boolean;
  /** option is visible but disabled */
  disabled?: boolean;
}

export interface IFieldsMenuProps {
  options: IFieldsMenuOption[];
  onChoose: (option: IFieldsMenuOption) => void;
  draggableProps?: {
    onChangeOrder: (oldIndex: number, newIndex: number) => void;
    checkDraggability?: (oldIndex: number, newIndex: number) => boolean;
  };

  size: "medium" | "small" | "large" | undefined;
}

export const FieldsMenu = ({
  options,
  onChoose,
  draggableProps,
  size = "medium",
}: IFieldsMenuProps) => {
  const [menuEl, setMenuEl] = React.useState(null);
  const { t } = useLang();
  const { saveLocalStorageUserConfig } = useAuth();
  const openMenu = (event: any) => {
    setMenuEl(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuEl(null);
    if (saveLocalStorageUserConfig) {
      saveLocalStorageUserConfig();
    }
  };

  //use of draggble hook is conditional and depend of whether onChangeOrder is provided
  //we can't call hook inside if statment so we do it like this
  const draggbleHook = useDraggable(
    draggableProps?.onChangeOrder || (() => {}),
    {
      itemIdPrefix: "fields-menu-draggable-item-",
      checkDraggability: draggableProps?.checkDraggability,
    }
  );
  let draggable: any;
  if (draggableProps) {
    draggable = draggbleHook;
  }

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={openMenu}
        variant="contained"
        color="secondary"
        size={size}
      >
        {t("showHide")}
        {Boolean(menuEl) ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
      </Button>
      <Menu
        id="options-menu"
        anchorEl={menuEl}
        open={Boolean(menuEl)}
        onClose={closeMenu}
      >
        {options.map((option, key) => {
          const isItemDraggable = key < COLUMN_FREEZE_INDEX + 1;

          //option is hidden but still counted in drag & drop
          if (option.hidden) {
            return null;
          }

          return (
            <MenuItem key={key} onClick={() => onChoose(option)}>
              <Box
                {...draggable?.getDraggbleProps(key, {
                  onEnterEffect: (e: any) => {
                    const currentDraggableItem = document.getElementById(
                      `fields-menu-draggable-item-${key}`
                    );
                    if (currentDraggableItem) {
                      currentDraggableItem.style.paddingBottom = "30px";
                    }
                  },
                  onLeaveEffect: (e: any) => {
                    const currentDraggableItem = document.getElementById(
                      `fields-menu-draggable-item-${key}`
                    );
                    if (currentDraggableItem) {
                      currentDraggableItem.style.paddingBottom = "0px";
                    }
                  },
                  onCleanupEffect: (e: any) => {
                    for (var element of document.querySelectorAll(
                      '[id^="fields-menu-draggable-item"]'
                    )) {
                      element.setAttribute("style", "");
                    }
                  },
                })}
                draggable={isItemDraggable ? "true" : "false"}
                display="flex"
                alignItems="center"
              >
                {isItemDraggable && draggable ? (
                  draggable.getDraggingButton()
                ) : (
                  <Box width={47.97} />
                )}
                <Checkbox
                  checked={option.value}
                  name={option.title}
                  key={key}
                  disabled={option.disabled}
                />
                {option.title}
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
