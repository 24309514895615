import React from "react";
import _ from "lodash";
import { ObjectId } from "mongoose";
import { useBookingForm } from "hooks/bookings/bookingForm";
import { useBookingActions } from "hooks/bookings/bookingActions";
import { useStore } from "context/store";
import { BookingFieldId, IBooking } from "navision-proxy-api/@types";
import { useAuth } from "context/auth";
import { useLang } from "context/lang";
import { useSnackbar } from "context/snackbar";
import { BookingForm } from "components/BookingForm";

interface IProps {
  quickFormId: ObjectId;
  onSubmit: () => void;
  isLocal: boolean;
}

export default ({ quickFormId, onSubmit, isLocal }: IProps) => {
  const {
    booking,
    customersCustomerLookup,
    setEnableCCLookup,
    setBooking,
    pushCustomersCustomer,
    handleChangeField,
    errors,
    setErrors,
    handleClearAddresses,
  } = useBookingForm({ quickForm: quickFormId });

  const { createBooking, loading } = useBookingActions();
  const { bookingFormData } = useStore();

  const {
    user: { appConfig },
  } = useAuth();

  const { t } = useLang();

  const { openSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    if (validateNewLine()) {
      //validated booking
      await createBooking({
        ...booking,
        ...Object.fromEntries(
          //only not empty values
          Object.entries(booking).filter(([_, v]) => v != "")
        ), //for addresses and dates
        goods: appConfig?.formDefaults.goodsUnit.map((key: string) => [
          key,
          "",
        ]),
        packages: appConfig?.formDefaults.packagesUnits.map((key: string) => [
          key,
          "",
        ]),
        quickForm: quickFormId,
        customersCustomer: String(customersCustomerLookup[0].id),
        customerNb: customersCustomerLookup[0].ccNumber,
        isLocal,
      } as IBooking); // is validated
      onSubmit();
    }
  };

  const validateNewLine = () => {
    const newErrors: Partial<Record<BookingFieldId, string>> = {};
    bookingFormData.fields
      .filter((field) => field.isAddress)
      .map<BookingFieldId>((field) => field.id)
      .forEach((field) => {
        if (!booking[field as keyof IBooking]) {
          newErrors[field as keyof IBooking] = t("errorRequired");
        }
      });
    setErrors(newErrors);

    console.log(newErrors);
    const isOkay = _.isEmpty(newErrors);
    if (!isOkay) {
      openSnackbar(
        Object.entries(newErrors)
          .map(([key, value]) => `${t(key)}:${value}`)
          .join(" "),
        "error"
      );
    }
    return isOkay;
  };

  return (
    <BookingForm
      hidePackagePickers
      setEnableCCLookup={setEnableCCLookup}
      pushCustomersCustomer={pushCustomersCustomer}
      setBooking={setBooking}
      booking={booking}
      handleChangeField={handleChangeField}
      customersCustomerLookup={customersCustomerLookup}
      loading={Boolean(loading["create"])}
      ignoredFields={bookingFormData.fields
        .filter((field) => !field.isAddress)
        .map((field) => field.id)}
      handleSubmit={handleSubmit}
      handleClearAddresses={handleClearAddresses}
      errors={errors}
      disableHiddenFields
    />
  );
};
