import React from "react";
import {
  Box,
  Button,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { useApi } from "hooks/api";
import { BookingStatus, IBooking } from "navision-proxy-api/@types";
import { Loader } from "components/Loader";
import { useLang } from "context/lang";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "styles/index";
import { useAuth } from "context/auth";
import routes from "config/routes";
import moment from "moment";

export const getPickupDate = (booking: IBooking) => {
  const date = booking?.pickupDate
    ? moment.utc(booking.pickupDate).format("dddd Do MMM")
    : "";
  const time = booking?.pickupTime || "";
  return date + " " + time;
};

export const getDeliveryDate = (booking: IBooking) => {
  if (booking?.latestDeliveryDate || booking?.deliveryDate) {
    const date = moment
      .utc(booking.latestDeliveryDate || booking.deliveryDate)
      .format("DD-MM-YYYY");

    let time = booking.latestDeliveryTime || booking.deliveryTime || "";
    console.log(time);
    if (time.includes(":")) {
      time = time.split(":").slice(0, 2).join(":");
    }

    return date + " " + time;
  }
  return "";
};

export default ({ publicMode = false }: { publicMode?: boolean }) => {
  const { trackingId } = useParams<{ trackingId: string }>();
  const history = useHistory();
  const { fetchBookingByTrackingNumber } = useApi();
  const { isAuthenticated } = useAuth();
  const { t } = useLang();
  const theme = useTheme();
  const styles = useStyles();

  const [booking, setBooking] = React.useState<IBooking>();
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchBooking = async () => {
      if (trackingId) {
        setLoading(true);
        const _booking = await fetchBookingByTrackingNumber(trackingId);
        setBooking({
          ..._booking,
          //status: "deliveryPlanned" as BookingStatus,
        });
        setLoading(false);
      }
    };

    fetchBooking();
  }, [trackingId]);

  const statusProgress: { [key in BookingStatus]?: number } = {
    waiting: 0,
    orderReceived: 100 / 7,
    pickupPlanned: (100 / 7) * 2,
    ongoingPickup: (100 / 7) * 3,
    pickupCompleted: (100 / 7) * 4,
    deliveryPlanned: (100 / 7) * 5,
    ongoingDelivery: (100 / 7) * 6,
    deliveryCompleted: 100,
  };

  const statusProgressPercent =
    statusProgress[booking?.status || "waiting"] || 0;

  const isPickupCompleted =
    statusProgressPercent >=
    (statusProgress["pickupCompleted" as BookingStatus] || 0);

  const isDeliveryCompleted =
    statusProgressPercent >=
    (statusProgress["deliveryCompleted" as BookingStatus] || 0);

  const [copyClicked, setCopyClicked] = React.useState(false);

  return (
    <Box className={styles.bookingsDetails}>
      <Loader loading={loading}>
        {booking ? (
          <Box>
            <Box>
              {!publicMode && (
                <>
                  <Box mb={2}>
                    <Button
                      onClick={() => history.goBack()}
                      variant="outlined"
                      color="primary"
                    >
                      <Box height={24} mr={1}>
                        <Icon color="primary">arrow_back</Icon>{" "}
                      </Box>
                      {t("back")}
                    </Button>
                  </Box>
                </>
              )}
              <Box>
                <Typography variant="caption">
                  {t("bookingId") + ":"}
                </Typography>
                <Typography variant="h5">{booking.bookingId}</Typography>
              </Box>
              <Box mt={4}>
                <Typography variant="caption">{t("status") + ":"}</Typography>
                <Typography variant="h5">{`${t(
                  booking.status || "waiting"
                )}`}</Typography>
              </Box>
            </Box>
            <Box width={{ sm: "100%", lg: "50%" }} mt={20} mb={10}>
              <div
                style={{
                  width: "100%",
                  background: `linear-gradient(90deg, rgba(0,0,0,0) ${Math.max(
                    statusProgressPercent == 100
                      ? 100
                      : statusProgressPercent - 10,
                    0
                  )}%, rgba(255,255,255,1) ${Math.min(
                    statusProgressPercent,
                    100
                  )}%)`,
                }}
              >
                <img
                  style={{ position: "relative", zIndex: -1 }}
                  width="100%"
                  src="https://www.alex-andersen.dk/cdn/assets/img/web-footer.gif"
                />
              </div>
              <Box mx={2}>
                <ProgressBar
                  percent={statusProgressPercent}
                  filledBackground={theme.palette.primary.main}
                >
                  {Object.keys(statusProgress).map((s, i) => {
                    const isFirst = i === 0;
                    const isLast = i == Object.keys(statusProgress).length - 1;

                    return (
                      <Step transition="scale">
                        {({ accomplished, index }) => (
                          <div
                            className={`transitionStep ${
                              accomplished ? "accomplished" : null
                            }`}
                          >
                            {isFirst && ( //first step
                              <>
                                <div className="step-upper-info">
                                  <Typography variant="caption">
                                    {t("pickup") + ":"}
                                  </Typography>
                                  <Typography variant="body1" noWrap={false}>
                                    {booking.pickupName}
                                  </Typography>
                                  {booking.pickupName !==
                                    booking.customerName && (
                                    <Typography variant="body2" noWrap={false}>
                                      ({booking.customerName})
                                    </Typography>
                                  )}
                                  <Typography variant="body2" noWrap={false}>
                                    {booking.pickupStreet}
                                  </Typography>
                                  <Typography variant="caption" noWrap={false}>
                                    {booking.pickupLocation}
                                  </Typography>
                                  <Box>
                                    <Typography variant="caption">
                                      {getPickupDate(booking)}
                                    </Typography>
                                  </Box>
                                </div>
                                {booking.actualPickup && (
                                  <div className="step-down-info">
                                    <Typography variant="caption">
                                      {t("actualPickup") + ":"}
                                    </Typography>
                                    <Typography
                                      color={
                                        isPickupCompleted
                                          ? "primary"
                                          : "initial"
                                      }
                                      variant="body2"
                                    >
                                      {booking.actualPickup}
                                    </Typography>
                                  </div>
                                )}
                              </>
                            )}
                            {/* {s == booking.status &&
                              !isFirst &&
                              !isLast && ( //current status
                                <Box display="flex" className="step-upper-info">
                                  <Icon
                                    className="transformed-icon"
                                    color="primary"
                                  >
                                    signal_cellular_alt
                                  </Icon>
                                  <Icon color="primary">local_shipping</Icon>
                                </Box>
                              )} */}
                            {isLast && ( //last step
                              <>
                                <div className="step-upper-info step-info-right">
                                  <Typography variant="caption">
                                    {t("delivery") + ":"}
                                  </Typography>
                                  <Typography variant="body1" noWrap={false}>
                                    {booking.deliveryName}
                                  </Typography>
                                  {booking.deliveryName !==
                                    booking.receiverName && (
                                    <Typography variant="body2" noWrap={false}>
                                      ({booking.receiverName})
                                    </Typography>
                                  )}
                                  <Typography variant="body2" noWrap={false}>
                                    {booking.deliveryStreet}
                                  </Typography>
                                  <Typography variant="caption" noWrap={false}>
                                    {booking.deliveryLocation}
                                  </Typography>
                                  <Box>
                                    <Typography variant="caption">
                                      {getDeliveryDate(booking)}
                                    </Typography>
                                  </Box>
                                </div>
                                {booking.actualDelivery && (
                                  <div className="step-down-info step-info-right">
                                    <Typography variant="caption">
                                      {t("actualDelivery") + ":"}
                                    </Typography>
                                    <Typography
                                      color={
                                        isDeliveryCompleted
                                          ? "primary"
                                          : "initial"
                                      }
                                      variant="body2"
                                    >
                                      {booking.actualDelivery}
                                    </Typography>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </Step>
                    );
                  })}
                </ProgressBar>
              </Box>
            </Box>
            <Box>
              <Typography variant="caption">{t("goods") + ":"}</Typography>
              {booking.goods.map(([key, value], index) => (
                <Typography key={index}>{`${key.replaceAll(
                  "&dot;",
                  "."
                )}: ${value}`}</Typography>
              ))}
            </Box>

            {booking.packages.length ? (
              <Box mt={2}>
                <Typography variant="caption">{t("packages") + ":"}</Typography>

                {booking.packages.map(([key, value]) => (
                  <Typography>{`${key.replaceAll(
                    "&dot;",
                    "."
                  )}: ${value}`}</Typography>
                ))}
              </Box>
            ) : null}

            <Box display="flex" flexDirection="column" mt={2}>
              {!publicMode && (
                <Box mb={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://${window.location.host}/track/${booking.trackingNumber}`
                      );
                      setCopyClicked(true);
                    }}
                  >
                    {copyClicked ? (
                      <Box display="flex" alignItems="center" mr={1}>
                        <Icon>check</Icon>
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center" mr={1}>
                        <Icon>content_copy</Icon>
                      </Box>
                    )}
                    {t("copyPublicLink")}
                  </Button>
                </Box>
              )}
              <Box mb={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    const subject = `${t("trackBookingMailSubject")} ${
                      booking.bookingId
                    }`;
                    const body = `${t("trackBookingMailBody")} \n\nhttps://${
                      window.location.host
                    }/track/${booking.trackingNumber}`;

                    window.open(
                      `mailto:?subject=${encodeURIComponent(
                        subject
                      )}&body=${encodeURIComponent(body)}`,
                      "_blank"
                    );
                  }}
                >
                  <Box display="flex" alignItems="center" mr={1}>
                    <Icon>send</Icon>
                  </Box>
                  {t("shareTrackingMail")}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          t("bookingNotFound")
        )}
      </Loader>
    </Box>
  );
};
