import React from "react";
import {
  IBooking,
  ICustomersCustomer,
  FieldParameters,
  Lookup,
  APIEntity,
  BookingFieldId,
  PackagingDescription,
} from "navision-proxy-api/@types";
import moment from "moment";
import _ from "lodash";

import { useLoading } from "hooks/loading";
import { useSnackbar } from "context/snackbar";
import { useStore } from "context/store";
import { useAuth } from "context/auth";
import { useFieldsSettings } from "context/fieldsSettings";
import { useInterval } from "utils/useInterval";

import { useApi } from "hooks/api";

export type BookingError = { [bookingId: string]: string };

export type FormErrors = {
  [bookingField in BookingFieldId]?: string | BookingError;
};

export function useBookingForm(initData?: Partial<IBooking>) {
  const [booking, setNewBooking] = React.useState<Partial<IBooking>>({});
  const bookingRef = React.useRef<Partial<IBooking>>({}); //we want to have ref for memoized cases
  React.useEffect(() => {
    bookingRef.current = booking;
  }, [booking]);

  console.log(booking, bookingRef);

  const setBooking = (booking: Partial<IBooking>) =>
    setNewBooking((prev) => ({ ...prev, ...booking }));

  const { loading } = useLoading(["load"]);

  const { checkPickupDate, fetchBookingFormData } = useApi();

  const { openSnackbar } = useSnackbar();

  const {
    bookingFormData,
    getCustomerCustomersLookup,
    loadBookingFormData,
    templateModeProps,
  } = useStore();
  const {
    user: { appConfig, userId },
  } = useAuth();
  const { hiddenFields } = useFieldsSettings();

  /** Templates for addresses.  */
  const [customersCustomerLookup, setCustomersCustomerLookup] = React.useState<
    ICustomersCustomer[]
  >([]);

  /** On/off templates suggestion. Used when we want to add new address which is not in templates list. */
  const [enableCCLookup, setEnableCCLookup] = React.useState<boolean>(true);

  /** TODO: errors/vaalidation logic should be isolated as a hook */
  const [errors, setErrors] = React.useState<FormErrors>({});

  React.useEffect(() => {
    if (bookingFormData.fields.length) {
      initBookingForm(initData);
    }
  }, [appConfig?.formDefaults, bookingFormData.fields, templateModeProps]);

  React.useEffect(() => {
    _calculateCustomersCustomerLookup();
  }, [
    bookingFormData.fields, //TODO check if that effect is needed
    booking,
    enableCCLookup,
  ]);

  React.useEffect(() => {
    _autofillAddresses();
  }, [customersCustomerLookup]);

  React.useEffect(() => {
    _validatePickupDate();
  }, [booking.delivery]);

  /**
   * WHAT IS CUSTOMERS CUSTOMER LOOKUP ?
   * customers customer lookup is a list of set of addresses (four addresses:receiver,delivery,pickup,customer) that we can use
   * we cannot use whichever addresses for this four addresses, we can only use predifined sets of four address
   * for example CC lookup = [ [1,1,2,3], [1,2,3,4], [2,2,2,2]] where numbers are addresses
   * in this example we cannot use address [1,2,2,4] because it is not in the list on CC lookup
   * also if we pick the first address as 1 than we can use only two first sets of addresses from CC lookup */
  const _calculateCustomersCustomerLookup = () => {
    if (bookingFormData.fields.length > 0) {
      let newCustomersCustomerLookup = getCustomerCustomersLookup();
      if (enableCCLookup) {
        const addressValues = bookingFormData.fields
          .filter(
            ({ id, isAddress }) => isAddress && booking[id as keyof IBooking]
          )
          .reduce(
            (acc, field) => ({
              ...acc,
              [field.id]: booking[field.id as keyof IBooking],
            }),
            {}
          );
        //we filter customers customer items which match the combination of addresses
        newCustomersCustomerLookup = newCustomersCustomerLookup.filter(
          (ccItem) => {
            const isAddressesMatch = Object.entries(addressValues).every(
              //every current address value should be in the ccItem
              ([key, value]) =>
                ccItem[`${key}Code` as keyof ICustomersCustomer] === value
            );

            const isCustomerNumberMatch = booking.customerNb // if there is a ccNumber it should also match cc value
              ? booking.customerNb === ccItem["ccNumber"]
              : true;

            return isAddressesMatch && isCustomerNumberMatch;
          }
        );
      }
      setCustomersCustomerLookup([
        ...newCustomersCustomerLookup,
        ...customersCustomerLookup.filter(
          (
            //leave new addresses
            ccItem
          ) => !enableCCLookup && Object.values(ccItem).includes("newAddress")
        ),
      ]);
    }
  };

  const pushCustomersCustomer = (cc: ICustomersCustomer) => {
    //get fields names for which we want to use new addresses
    const newAddressFields = Object.entries(cc)
      .filter(([_, value]) => value == "newAddress")
      .map(([key, _]) => key);

    setCustomersCustomerLookup((prev) => {
      //filter older new addresses records
      const newCCLookup = prev.map<ICustomersCustomer>((ccItem) => {
        let ccItemWithoutNewAddress = ccItem;
        newAddressFields.forEach((newAddressField) => {
          if (
            //if there is already new address for disared field
            ccItemWithoutNewAddress[
              newAddressField as keyof ICustomersCustomer
            ] == "newAddress"
          ) {
            ccItemWithoutNewAddress = Object.fromEntries(
              //filter old new address entries
              Object.entries(ccItemWithoutNewAddress).filter(
                ([key]) => !key.includes(newAddressField)
              )
            ) as ICustomersCustomer;
          }
        });
        return ccItemWithoutNewAddress;
      });

      return [...newCCLookup, cc];
    });
  };

  /** If there is 1 customers customer lookup left, we can use it for autofilling addresses. */
  const _autofillAddresses = () => {
    if (
      customersCustomerLookup?.length === 1 &&
      bookingFormData.fields.some(
        //have unfilled fields
        ({ id, isAddress }) => isAddress && !booking[id as keyof IBooking]
      )
    ) {
      setBooking({
        ...booking,
        ...bookingFormData.fields
          .filter(({ isAddress }) => isAddress)
          .reduce(
            (acc, { id }) => ({
              ...acc,
              [id]: customersCustomerLookup[0][
                `${id}Code` as keyof ICustomersCustomer
              ],
            }),
            {}
          ),
        customerNb: customersCustomerLookup[0].ccNumber,
      });
    }
  };

  /** Check wether it is not to late to use current pickup date for current delivery address. */
  const _validatePickupDate = async () => {
    const { delivery, pickupDate } = booking;
    const deliveryCountry = customersCustomerLookup.find(
      ({ deliveryCode }) => deliveryCode === delivery
    )?.deliveryCountry;

    if (delivery && deliveryCountry && pickupDate && userId) {
      console.log("pickupDate", pickupDate);
      const { AfterDeadline } = await checkPickupDate(
        moment(pickupDate).utc(true).toISOString(),
        deliveryCountry,
        userId
      );

      if (AfterDeadline) {
        openSnackbar("deadlinePickupDateChange", "warning");
        setBooking({
          pickupDate: moment(pickupDate).add(1, "day").toDate(),
        } as IBooking);
      }
    }
  };

  /** Get initial booking form data from booking fields settings and user settings using lookups and defaults */
  const _getInitBookingData = (fields: FieldParameters[]): IBooking => {
    const addressDefaults = !hiddenFields.includes("customer")
      ? {
          customer: appConfig?.formDefaults.customerAddress,
        }
      : { pickup: appConfig?.formDefaults.customerAddress };

    const autoselectFirstDefaults = fields
      .filter(
        ({ id, autoselectFirst, lookup }) =>
          autoselectFirst &&
          !hiddenFields.includes(id) &&
          lookup &&
          lookup.length
      )
      .reduce((acc, { id, lookup }) => {
        if (lookup) {
          const lookupFirstValue = lookup[0];
          if (lookupFirstValue instanceof String) {
            return { ...acc, [id]: lookupFirstValue };
          } else if ((lookupFirstValue as Lookup[number]).key) {
            return { ...acc, [id]: (lookupFirstValue as Lookup[number]).key };
          } else {
            return { ...acc, [id]: (lookupFirstValue as APIEntity)._id };
          }
        } else {
          return { ...acc, [id]: "" };
        }
      }, {});

    const initPickupDate =
      appConfig?.createBooking?.savePickupDate &&
      localStorage.getItem("latestPickupDate") &&
      new Date(localStorage.getItem("latestPickupDate") || "") > new Date()
        ? new Date(localStorage.getItem("latestPickupDate") || "")
        : new Date();

    const initPickupTime =
      appConfig?.createBooking?.savePickupTime &&
      localStorage.getItem("latestPickupTime")
        ? localStorage.getItem("latestPickupTime") || ""
        : "";

    const initData = {
      ...fields
        // .filter(
        //   ({ readOnly, id }) =>
        //     !readOnly && !id.includes("package") && !id.includes("goods")
        // )
        .reduce<IBooking>(
          (acc, { id }) => ({ ...acc, [id]: "" }),
          {} as IBooking
        ),
      bookingId: "",
      customersCustomer: "",
      customerNb: "",
      goods: appConfig?.formDefaults.goodsUnit.map((p: string) => [p, ""]),
      goodsDescription: [] as PackagingDescription,
      packages: appConfig?.formDefaults.packagesUnits.map((p: string) => [
        p,
        "",
      ]),
      packagesDescription: [] as PackagingDescription,
      pickupDate: initPickupDate,
      pickupTime: initPickupTime,
      deliveryDate: undefined,
      deliveryTime: undefined,
      latestDeliveryDate: undefined,
      latestTime: undefined,
      ...addressDefaults,
      ...autoselectFirstDefaults,
      ...templateModeProps,
    };

    return initData;
  };

  const _refreshInitValues = async () => {
    const data = await fetchBookingFormData();
    const initData = _getInitBookingData(data.fields);

    const refreshSettings = [
      {
        id: "tourTime",
        refreshIf: (oldValue: any, newValue: any) =>
          new Date(oldValue) < new Date(newValue),
      },
    ];

    const refreshValues = refreshSettings.reduce((acc, value) => {
      const oldValue = booking[value.id as keyof IBooking];
      const newValue = initData[value.id as keyof IBooking];
      if (oldValue && newValue && value.refreshIf(oldValue, newValue)) {
        return { ...acc, [value.id]: newValue };
      } else {
        return acc;
      }
    }, {});

    if (!_.isEmpty(refreshValues)) {
      //update booking form data
      await loadBookingFormData(); //TODO check if it updates tourtimes correctly
      setBooking(refreshValues);
    }
  };

  useInterval(_refreshInitValues, 1000 * 5 * 60);

  const handleClearAddresses = (addressId: keyof IBooking) => {
    //TODO: delete only one address

    if (customersCustomerLookup.length === 1) {
      const addressesEmptyFields = {} as IBooking;

      if (addressId == "customer") {
        addressesEmptyFields.receiver = "";
        addressesEmptyFields.delivery = "";
        addressesEmptyFields.customer = "";
        addressesEmptyFields.pickup = "";
        addressesEmptyFields.customerNb = "";
      } else {
        addressesEmptyFields.receiver = "";
        addressesEmptyFields.pickup = "";
        addressesEmptyFields.delivery = "";
        addressesEmptyFields.customerNb = "";
      }
      setBooking({ ...addressesEmptyFields });
    } else {
      setBooking({ [addressId]: "" });
    }
  };

  const initBookingForm = (initData?: Partial<IBooking>): IBooking => {
    const initBooking = {
      ..._getInitBookingData(bookingFormData.fields),
      ...initData,
    };

    setBooking(initBooking);
    if (!enableCCLookup) {
      setEnableCCLookup(true);
    }
    return initBooking;
  };

  const handleChangeField = (key: BookingFieldId, value: any) => {
    setBooking({ [key]: value || "" });
    if (errors[key]) {
      setErrors((prev) => ({ ...prev, [key]: "" }));
    }
  };

  return {
    loading,
    booking,
    bookingRef,
    handleChangeField,
    errors,
    setErrors,
    customersCustomerLookup,
    setBooking,
    initBookingForm,
    handleClearAddresses,
    enableCCLookup,
    setEnableCCLookup,
    pushCustomersCustomer,
  };
}
