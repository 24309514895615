import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import KeyListener from "components/KeyListener";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import { useLang } from "context/lang";

interface IProps {
  index: number;
  packageKey: string;
  value: string;
  lookup?: string[];
  editing?: boolean;
  variant?: "outlined" | "standard" | "filled" | undefined;
  placeholder?: string;
  inputLabel?: string;
  onChange: (index: number, key: string, value: string) => void;
  errorText?: string;
  hideValues?: boolean;
  /** used for having different translations for different package field types (units or packages) */
  valueTranslatePrefix?: string;
  descriptionLabel?: string;
  packagesDescriptions?: string[];
  onChangePackageDescriptions?: (index: number, newValue: string) => void;
}

export const PackageField = ({
  index,
  packageKey,
  value,
  lookup = [],
  editing = true,
  variant,
  placeholder,
  inputLabel,
  onChange,
  errorText,
  hideValues = false,
  valueTranslatePrefix,
  descriptionLabel,
  packagesDescriptions,
  onChangePackageDescriptions,
}: IProps) => {
  const [data, setData] = React.useState({
    packageKey,
    value,
  });
  const [error, setError] = React.useState(errorText);
  const { t } = useLang();
  React.useEffect(() => {
    setData({
      packageKey: !packageKey || packageKey === " " ? lookup[0] : packageKey,
      value,
    });
  }, [packageKey, value]);

  const handleChangeValue = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData((prev) => ({ ...prev, value }));
  };

  const handleSave = (key?: string, value?: string) => {
    if (key && value !== undefined) {
      onChange(index, key, value);
    } else {
      onChange(index, data.packageKey, data.value);
    }
  };

  return (
    <KeyListener
      keyName="Enter"
      action={(e: KeyboardEvent) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Box key={`${packageKey}_${value}_${index}`} mr={1} mb={2}>
        <Box display="flex">
          <Box width={!hideValues ? "70%" : "100%"} minWidth="110px">
            <TextField
              select
              fullWidth
              variant={variant}
              label={index === 0 && inputLabel}
              placeholder={placeholder}
              disabled={!editing}
              id={`package_${index}`}
              value={data.packageKey}
              error={Boolean(errorText || error)}
              //inputProps={{ onBlur: handleSave }}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                setData((prev) => ({ ...prev, packageKey: value }));
                handleSave(value, data.value);
              }}
            >
              {[...new Set([...lookup, packageKey])].map((option, i) => (
                <MenuItem key={`${option}_${i}`} value={option}>
                  {valueTranslatePrefix &&
                  t(`${valueTranslatePrefix}${option}`) !==
                    `${valueTranslatePrefix}${option}` //there is some translation
                    ? t(`${valueTranslatePrefix}${option}`)
                    : option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {!hideValues && (
            <Box
              display="flex"
              width="30%"
              px={variant === "outlined" ? 0 : "0.25rem"}
              minWidth="110px"
            >
              <TextField
                id={`packageQuantity_${index}`}
                type="number"
                placeholder={t("quantity")}
                variant={variant}
                value={String(data.value) || ""}
                disabled={!editing}
                onChange={(e) => handleChangeValue(e)}
                error={Boolean(errorText || error)}
                //helperText={error}
                inputProps={{
                  onBlur: () => handleSave(),
                  // inputmode: "numeric",
                  //pattern: "[0-9]*",
                  min: 0,
                  onFocus: ({ target }) => {
                    target.select();
                  },
                  onKeyDown: function (evt) {
                    if (evt.key == "Enter") {
                      evt.stopPropagation();
                    }

                    if (
                      ![
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "Backspace",
                        "Del",
                        "Delete",
                        "Tab",
                      ].includes(evt.key)
                    ) {
                      evt.preventDefault();
                    }
                  },
                }}
              />
            </Box>
          )}
        </Box>
        {onChangePackageDescriptions && (
          <Box mt={2}>
            <TextField
              value={
                packagesDescriptions && packagesDescriptions[index]
                  ? packagesDescriptions[index]
                  : ""
              }
              onChange={(e) =>
                onChangePackageDescriptions(index, e.target.value)
              }
              //inputProps={{ onBlur: onChangeDescription }}
              label={descriptionLabel}
              inputProps={{ maxLength: 30 }}
            />
          </Box>
        )}
      </Box>
    </KeyListener>
  );
};
